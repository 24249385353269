import React from 'react';
import Seo from '../components/config/Seo';

const NotFound = () => {
  return (
    <>
      <Seo />
    </>
  );
};

export default NotFound;
